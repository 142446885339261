var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Button from '@shared/ui/buttons/Button';
const ButtonStyled = styled(Button, { shouldForwardProp: propName => propName !== 'isActive' })(props => ({
    color: props.isActive ?
        props.theme.palette.primary.main :
        props.theme.palette.text.secondary,
    borderColor: props.isActive ?
        props.theme.palette.primary.main :
        props.theme.customColors.input.border,
}));
const ViewTypeButton = (_a) => {
    var { isActive = false } = _a, props = __rest(_a, ["isActive"]);
    return (_jsx(ButtonStyled
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { isActive: isActive })));
};
export default ViewTypeButton;
